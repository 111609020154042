import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../layouts/default"
import Seo from "../components/seo"
import Back from "../components/back"
import Swiper from "../components/swiper"

const ProjectsPage = props => {
    return (
        <Layout className="projects" hasHeader={false}>
            <Seo title={props.pageContext.title} />
            {props.pageContext.edges.map(({ node }) => (
                <div key={node.id}>
                    <Swiper
                        to={`/${props.pageContext.title}/${node.project.title}`}
                        back={"/"}
                    >
                        <GatsbyImage
                            image={node.project.image_1.asset.gatsbyImageData}
                            alt={node.project.title}
                        />
                    </Swiper>

                    {node.project.image_2 && (
                        <Swiper
                            to={`/${props.pageContext.title}/${node.project.title}`}
                            back={"/"}
                        >
                            <GatsbyImage
                                image={
                                    node.project.image_2.asset.gatsbyImageData
                                }
                                alt={node.project.title}
                            />
                        </Swiper>
                    )}

                    <span>{node.project.title}</span>
                </div>
            ))}
            <Back to={"/"} />
        </Layout>
    )
}

export default ProjectsPage
